import React from "react"
import { navigate } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import { Menu, Avatar } from 'antd'
import { UserOutlined, LogoutOutlined, ProfileOutlined, CloseOutlined, MenuOutlined } from '@ant-design/icons';

export default function NavBar({ onSiderTrigger, siderCollapsed, onSignOut, profile }) {

    const onClick = ({ key }) => {
        if (key === "0") {
            navigate(`/app/profile`)
        } else if (key === "1") {
            onSignOut()
        }
    };

    return (
        <div className="aappoint-nav-bar">
            <div className="aappoint-nav-bar-left-menu">
                <div className="aappoint-nav-bar-left-item aappoint-lg-hidden">
                    {
                        siderCollapsed ?
                            <button className="aappoint-sider-trigger" onClick={onSiderTrigger} aria-label="Menu"><MenuOutlined className="aappoint-sider-trigger-icon" /></button> :
                            <button className="aappoint-sider-trigger" onClick={onSiderTrigger} aria-label="Menu"><CloseOutlined className="aappoint-sider-trigger-icon" /></button>
                    }

                </div>
                <div className="aappoint-nav-bar-left-item aappoint-xs-hidden aappoint-lg-block">
                    <StaticImage
                        src="../images/icon.png"
                        alt="AAppoint"
                        placeholder="blurred"
                        layout="fixed"
                        width={36}
                        height={36}
                    />
                </div>
                <div className="aappoint-nav-bar-left-item  aappoint-xs-hidden aappoint-lg-block">
                    <div style={{ color: "white", fontSize: "large" }}>AAppoint</div>
                </div>
            </div>
            <div className="aappoint-nav-bar-center-menu aappoint-xs-flex aappoint-lg-hidden">
                <div className="aappoint-nav-bar-center-item aappoint-lg-hidden">
                    <div style={{ color: "white", fontSize: "large" }}>AAppoint</div>
                </div>
            </div>

            <div className="aappoint-nav-bar-right-menu">
                {
                    profile &&
                    (
                        <Menu onClick={onClick}
                            mode="horizontal"
                            theme="dark"
                            selectable={false}
                            style={{ backgroundColor: "transparent" }}
                        >
                            <Menu.SubMenu
                                key="SubMenu"
                                icon={
                                    (profile.picture) ?
                                        <Avatar src={profile.picture} /> :
                                        <Avatar icon={<UserOutlined />} />
                                }
                            >
                                <Menu.Item key="0" icon={<ProfileOutlined />} >
                                    Profile
                                </Menu.Item>
                                <Menu.Item key="1" icon={<LogoutOutlined />} >
                                    Logout
                                </Menu.Item>
                            </Menu.SubMenu>
                        </Menu>
                    )
                }
            </div>

        </div >
    )
}