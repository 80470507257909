import React, { useState, useCallback } from "react"
import NavBar from "./nav-bar"
import SideMenu from './side-menu'
import Footer from './footer'
import { useFirebase } from './firebase/context'
import { getProfile as apiGetProfile } from '../services/api'
import { navigate } from "gatsby"

import { Layout as AntdLayout, BackTop } from 'antd';

const Layout = ({ children, showSider }) => {
    const [collapsed, setCollapsed] = useState(false)
    const [collapsedWidth, setCollapsedWidth] = useState(80)
    const [firebaseData, setFirebaseData] = useState(null)
    const [profile, setProfile] = useState(null)

    const fetchProfile = useCallback(async () => {
        if (firebaseData && firebaseData.currentUser) {
            console.log("FETCH PROFILE")
            try {
                const token = await firebaseData.currentUser.getIdToken()
                const resp = await apiGetProfile({ token })
                if (resp) {
                    setProfile(resp.data)
                }
            } catch (err) {
                //notifyError({ description: "Fail to fetch your profile from the server" })
            }
        }
    }, [firebaseData])

    useFirebase(firebase => {
        if (!firebaseData) {
            setFirebaseData({ firebase, currentUser: firebase.auth().currentUser });
            return
        }
        fetchProfile()
    }, [fetchProfile])

    const onBreakpoint = (broken) => {
        setCollapsedWidth(broken ? 0 : 80)
    }

    const onCollapse = (v) => {
        setCollapsed(v)
    }

    const onSiderTrigger = () => {
        onCollapse(!collapsed)
    }

    const onSignOut = async () => {
        firebaseData.firebase.auth().signOut().then(() => {
            navigate(`/`)
        })
    }

    return (
        <AntdLayout style={{ minHeight: '100vh' }}>
            <AntdLayout.Header style={{ position: 'fixed', zIndex: '50', width: '100%' }}>
                <NavBar onSiderTrigger={onSiderTrigger} siderCollapsed={collapsed} profile={profile} onSignOut={onSignOut} />
            </AntdLayout.Header>
            <AntdLayout hasSider={showSider} style={{ marginTop: '64px' }}>
                {
                    showSider && (
                        <>
                            <AntdLayout.Sider
                                collapsedWidth={collapsedWidth}
                                collapsible
                                collapsed={collapsed}
                                onCollapse={onCollapse}
                                onBreakpoint={onBreakpoint}
                                breakpoint="lg"
                                zeroWidthTriggerStyle={{ display: "none" }}
                                style={{ overflow: "auto" }}
                            >
                                <SideMenu profile={profile} />
                            </AntdLayout.Sider>
                        </>
                    )
                }
                <AntdLayout>
                    <AntdLayout.Content style={{ margin: '0 16px' }}>
                        {children}
                        <BackTop />
                    </AntdLayout.Content>
                    <AntdLayout.Footer>
                        <Footer />
                    </AntdLayout.Footer>
                </AntdLayout>
            </AntdLayout>
        </AntdLayout>
    )

}
export default Layout