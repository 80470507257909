import React from 'react'
import { FacebookOutlined, InstagramOutlined, YoutubeOutlined } from '@ant-design/icons';

const Footer = () => (
    <div className="aappoint-footer">
        <div>
            <small>
                © {new Date().getFullYear()} {` `}
                <a href="https://www.facebook.com">Sprite Consulting Co., Ltd.</a>
                {` `}&nbsp;all rights reserved.
                {` `}-&nbsp;Powered by <a href="https://www.hypcode.co">Hypcode</a>
            </small>
        </div>
        <div className="aappoint-footer-right-group">
            <div className="aappoint-footer-right-item">
                <a href="https://www.facebook.com/AAppoint.me" target="_blank" rel="noreferrer" aria-label="Facebook"><FacebookOutlined /></a>
            </div>
            <div className="aappoint-footer-right-item">
                <a href="https://www.instagram.com/aappoint_official/" target="_blank" rel="noreferrer" aria-label="Instagram"><InstagramOutlined /></a>
            </div>
            <div className="aappoint-footer-right-item">
                <a href="https://www.youtube.com/channel/UCIbSLJ7qHs71QEIJkjwRiEA" target="_blank" rel="noreferrer" aria-label="Youtube"><YoutubeOutlined /></a>
            </div>
        </div>
    </div>
);

export default Footer;