import axios from 'axios';

const API_BASE_URL = process.env.GATSBY_AAPPOINT_BACKOFFICE_API_BASE_URL

const getDefaultHeaders = (token) => {
    return {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': `Bearer ${token}`,
    }
}

const doRequest = async ({ method, path, headers, data, ...rest }) => {
    const url = `${API_BASE_URL}${path}`;
    return axios({
        method,
        url,
        headers,
        data,
        withCredentials: true,
        ...rest
    })
}


export const listInvoices = async ({ token, page, pageSize, orderBy, filter }) => {
    const p = (page && page > 0) ? page : 1;
    const limit = (pageSize && pageSize > 0) ? pageSize : 20;
    const offset = (p - 1) * limit;
    var params = new URLSearchParams({ limit: `${limit}`, offset: `${offset}` });
    if (orderBy) {
        params.append("order_by", orderBy)
    }
    if (filter) {
        for (const [key, value] of Object.entries(filter)) {
            if (value) {
                params.append(key, value)
            }
        }
    }

    const query = params.toString()

    return doRequest({
        method: 'GET',
        path: `/invoices?${query}`,
        headers: getDefaultHeaders(token),
    })
}


export const getInvoice = async ({ token, id }) => {
    return doRequest({
        method: 'GET',
        path: `/invoices/${id}`,
        headers: getDefaultHeaders(token),
    })
}

export const getInvoiceHTML = async ({ token, id, lang }) => {
    const param = new URLSearchParams({ lang: (lang) ? lang : "en" })
    const query = param.toString()
    const { Accept, ...rest } = getDefaultHeaders(token)
    return doRequest({
        method: 'GET',
        path: `/invoices/${id}?${query}`,
        headers: {
            "Accept": "text/html",
            ...rest
        },
    })
}

export const getInvoicePDF = async ({ token, id, lang }) => {
    const param = new URLSearchParams({ lang: (lang) ? lang : "en" })
    const query = param.toString()
    const { Accept, ...rest } = getDefaultHeaders(token)
    return doRequest({
        method: 'GET',
        path: `/invoices/${id}?${query}`,
        headers: {
            "Accept": "application/pdf",
            ...rest
        },
        responseType: 'blob', // important
    })
}


export const getInvoiceStatus = async ({ token, id }) => {
    return doRequest({
        method: 'GET',
        path: `/invoices/${id}/status`,
        headers: getDefaultHeaders(token),
    })
}

export const setInvoiceStatus = async ({ token, id, status }) => {
    return doRequest({
        method: 'PATCH',
        path: `/invoices/${id}/status`,
        headers: getDefaultHeaders(token),
        data: { status }
    })
}

export const updateInvoice = async ({ token, id, invoice }) => {
    return doRequest({
        method: 'PUT',
        path: `/invoices/${id}`,
        headers: getDefaultHeaders(token),
        data: invoice,
    })
}

export const sendInvoice = async ({ token, id, email, lang, receiver_name }) => {
    return doRequest({
        method: 'POST',
        path: `/invoices/${id}/send`,
        headers: getDefaultHeaders(token),
        data: {
            email,
            lang,
            receiver_name
        },
    })
}

export const generateInvoice = async ({ token, shop_id, month, year }) => {
    return doRequest({
        method: 'POST',
        path: `/invoices`,
        headers: getDefaultHeaders(token),
        data: {
            month,
            year,
            shop_id,
        }
    })
}

export const listShops = async ({ token, page, pageSize, orderBy, filter }) => {
    const p = (page && page > 0) ? page : 1;
    const limit = (pageSize && pageSize > 0) ? pageSize : 20;
    const offset = (p - 1) * limit;
    var params = new URLSearchParams({ limit: `${limit}`, offset: `${offset}` });
    if (orderBy) {
        params.append("order_by", orderBy)
    }
    if (filter) {
        for (const [key, value] of Object.entries(filter)) {
            if (value) {
                params.append(key, value)
            }
        }
    }

    const query = params.toString()

    return doRequest({
        method: 'GET',
        path: `/shops?${query}`,
        headers: getDefaultHeaders(token),
    })
}

export const getShop = async ({ token, id }) => {
    return doRequest({
        method: 'GET',
        path: `/shops/${id}`,
        headers: getDefaultHeaders(token),
    })
}

export const listShopMembers = async ({ token, id, page, pageSize, orderBy, filter }) => {
    const p = (page && page > 0) ? page : 1;
    const limit = (pageSize && pageSize > 0) ? pageSize : 20;
    const offset = (p - 1) * limit;
    var params = new URLSearchParams({ limit: `${limit}`, offset: `${offset}` });
    if (orderBy && orderBy.length > 0) {
        params.append("order_by", orderBy)
    }
    if (filter) {
        for (const [key, value] of Object.entries(filter)) {
            console.log("KEY-VALUE", key, value)
            if (value) {
                params.append(key, value)
            }
        }
    }

    const query = params.toString()

    return doRequest({
        method: 'GET',
        path: `/shops/${id}/members?${query}`,
        headers: getDefaultHeaders(token),
    })
}

export const setShopStatus = async ({ token, id, status }) => {
    return doRequest({
        method: 'PATCH',
        path: `/shops/${id}/status`,
        headers: getDefaultHeaders(token),
        data: { status }
    })
}

export const listConfigs = async ({ token, page, pageSize, orderBy, filter }) => {
    const p = (page && page > 0) ? page : 1;
    const limit = (pageSize && pageSize > 0) ? pageSize : 20;
    const offset = (p - 1) * limit;
    var params = new URLSearchParams({ limit: `${limit}`, offset: `${offset}` });
    if (orderBy) {
        params.append("order_by", orderBy)
    }
    if (filter) {
        for (const [key, value] of Object.entries(filter)) {
            if (value) {
                params.append(key, value)
            }
        }
    }
    const query = params.toString()

    return doRequest({
        method: 'GET',
        path: `/configs?${query}`,
        headers: getDefaultHeaders(token),
    })
}


export const setConfig = async ({ token, key, value, label }) => {
    return doRequest({
        method: 'POST',
        path: `/configs`,
        headers: getDefaultHeaders(token),
        data: {
            key,
            value,
            label
        },
    })
}


export const listOwners = async ({ token, page, pageSize, orderBy, filter }) => {
    const p = (page && page > 0) ? page : 1;
    const limit = (pageSize && pageSize > 0) ? pageSize : 20;
    const offset = (p - 1) * limit;
    var params = new URLSearchParams({ limit: `${limit}`, offset: `${offset}`, roles: "shop_owner" });
    if (orderBy) {
        params.append("order_by", orderBy)
    }
    if (filter) {
        for (const [key, value] of Object.entries(filter)) {
            if (value) {
                params.append(key, value)
            }
        }
    }
    const query = params.toString()

    return doRequest({
        method: 'GET',
        path: `/customers?${query}`,
        headers: getDefaultHeaders(token),
    })
}


export const getOwner = async ({ token, id }) => {
    return doRequest({
        method: 'GET',
        path: `/customers/${id}`,
        headers: getDefaultHeaders(token),
    })
}

export const listUsers = async ({ token, page, pageSize, orderBy, filter }) => {
    const p = (page && page > 0) ? page : 1;
    const limit = (pageSize && pageSize > 0) ? pageSize : 20;
    const offset = (p - 1) * limit;
    var params = new URLSearchParams({ limit: `${limit}`, offset: `${offset}` });
    if (orderBy) {
        params.append("order_by", orderBy)
    }
    if (filter) {
        for (const [key, value] of Object.entries(filter)) {
            if (value) {
                params.append(key, value)
            }
        }
    }
    const query = params.toString();

    return doRequest({
        method: 'GET',
        path: `/users?${query}`,
        headers: getDefaultHeaders(token),
    })
}

export const getUser = async ({ token, uid }) => {
    return doRequest({
        method: 'GET',
        path: `/users/${uid}`,
        headers: getDefaultHeaders(token),
    })
}

export const addUser = async ({ token, email, role }) => {
    return doRequest({
        method: 'POST',
        path: `/users`,
        headers: getDefaultHeaders(token),
        data: {
            email,
            role
        },
    })
}

export const setUserRole = async ({ token, uid, role }) => {
    return doRequest({
        method: 'PATCH',
        path: `/users/${uid}/role`,
        headers: getDefaultHeaders(token),
        data: { role }
    })
}

export const deleteUser = async ({ token, uid }) => {
    return doRequest({
        method: 'DELETE',
        path: `/users/${uid}`,
        headers: getDefaultHeaders(token),
    })
}


export const getProfile = async ({ token }) => {
    return doRequest({
        method: 'GET',
        path: `/profile`,
        headers: getDefaultHeaders(token),
    })
}

export const setProfile = async ({ token, profile }) => {
    return doRequest({
        method: 'PUT',
        path: `/profile`,
        headers: getDefaultHeaders(token),
        data: profile,
    })
}

export const getShopSummary = async ({ token }) => {
    return doRequest({
        method: 'GET',
        path: `/data/shops/summary`,
        headers: getDefaultHeaders(token),
    })
}

export const getNewShopsData = async ({ token, from, to, mode }) => {
    var params = new URLSearchParams();
    if (from) {
        params.append("from", from)
    }
    if (to) {
        params.append("to", to)
    }
    if (mode) {
        params.append("mode", mode)
    } else {
        params.append("mode", "daily")
    }
    const query = params.toString();
    return doRequest({
        method: 'GET',
        path: `/data/shops/new?${query}`,
        headers: getDefaultHeaders(token),
    })
}


export const getUserSummary = async ({ token }) => {
    return doRequest({
        method: 'GET',
        path: `/data/users/summary`,
        headers: getDefaultHeaders(token),
    })
}

export const getNewUsersData = async ({ token, from, to, mode }) => {
    var params = new URLSearchParams();
    if (from) {
        params.append("from", from)
    }
    if (to) {
        params.append("to", to)
    }
    if (mode) {
        params.append("mode", mode)
    } else {
        params.append("mode", "daily")
    }
    const query = params.toString();
    return doRequest({
        method: 'GET',
        path: `/data/users/new?${query}`,
        headers: getDefaultHeaders(token),
    })
}