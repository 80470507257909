import React from 'react';
import { navigate } from 'gatsby';
import { Menu } from 'antd';
import { TeamOutlined, DashboardOutlined, ShopOutlined, DollarCircleOutlined, CrownOutlined, SettingOutlined } from '@ant-design/icons';
import { useLocation } from '@reach/router'

const pathKeyMap = {
    "/app/dashboard": "1",
    "/app/invoices": "2",
    "/app/shops": "3",
    "/app/owners": "4",
    "/app/users": "5",
    "/app/settings": "6",
}

const SideMenu = ({ profile }) => {
    const location = useLocation()
    const role = (profile && profile.role) ? profile.role : 'anonymous'

    const onClick = ({ key }) => {
        switch (key) {
            case '1':
                navigate('/app/dashboard')
                break;
            case '2':
                navigate('/app/invoices')
                break;
            case '3':
                navigate('/app/shops')
                break;
            case '4':
                navigate('/app/owners')
                break;
            case '5':
                navigate('/app/users')
                break;
            case '6':
                navigate('/app/settings')
                break;
            default:
                break;
        }
    };

    const currentKey = pathKeyMap[location.pathname]
    var selectedKeys = (currentKey && currentKey.length > 0) ? [currentKey] : null
    if (!selectedKeys) {
        for (const [key, value] of Object.entries(pathKeyMap)) {
            if (location.pathname.substring(0, key.length) === key) {
                selectedKeys = [value]
                break
            }
        }
    }

    return (
        <Menu theme="dark" defaultSelectedKeys={['1']} selectedKeys={selectedKeys} mode="inline" onClick={onClick}>
            {
                ((role === 'admin') || (role === 'finance') || (role === 'user')) &&
                <>
                    <Menu.Item key='1' icon={<DashboardOutlined />}>
                        Dashboard
                    </Menu.Item>
                    {
                        ((role === 'admin') || (role === 'finance')) &&
                        <Menu.Item key='2' icon={<DollarCircleOutlined />}>
                            Invoices
                        </Menu.Item>
                    }
                    {
                        ((role === 'admin') || (role === 'user')) &&
                        <>
                            <Menu.Item key='3' icon={<ShopOutlined />}>
                                Shops
                            </Menu.Item>
                            <Menu.Item key='4' icon={<CrownOutlined />}>
                                Owners
                            </Menu.Item>
                        </>
                    }
                    {
                        (role === 'admin') &&
                        <>
                            <Menu.Item key='5' icon={<TeamOutlined />}>
                                Users
                            </Menu.Item>
                            <Menu.Item key='6' icon={<SettingOutlined />}>
                                Settings
                            </Menu.Item>
                        </>
                    }
                </>
            }

        </Menu >
    )
}

export default SideMenu;